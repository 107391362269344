
























































































































































































































































































































































































































































































































import Vue from 'vue'
import Card from '@/components/Card.vue'

import BaseNotification from '@/components/BaseComponents/BaseNotification/BaseNotification.vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import BasePagePreloader from '@/components/BasePagePreloader.vue'
import BaseInput from '@/components/BaseInputVuelidate.vue'
import BudgetModal from '@/components/ADSManager/NewCampaign/BudgetModal.vue'
import NewCampaignLayout from '@/components/ADSManager/NewCampaignLayout.vue'
import BaseAlert from '@/components/BaseComponents/BaseNotification/BaseAlert.vue'
import MainBudgetSettings from '@/components/ADSManager/NewCampaign/Budget/MainBudgetSettings.vue'
import BudgetScheduler from '@/components/ADSManager/NewCampaign/Budget/BudgetScheduler.vue'
import Bidding from '@/components/ADSManager/NewCampaign/Budget/Bidding.vue'
import FrequencyCap from '@/components/ADSManager/NewCampaign/Budget/FrequencyCap.vue'
import ConversionOptions from '@/components/ADSManager/NewCampaign/Budget/ConversionOptions.vue'
import Optimizing from '@/components/ADSManager/NewCampaign/Budget/Optimizing.vue'
import { mapActions, mapGetters } from 'vuex'
import { REACH, CONVERSIONS } from '@/constants/Campaign'
import 'vue2-datepicker/index.css'
import onlyNumbers from '@/utils/onlyNumbers'
export default Vue.extend({
  name: 'Budget',
  components: {
    BudgetModal,
    Card,
    BasePagePreloader,
    // BaseNotification,
    // VSelect,
    NewCampaignLayout,
    // BaseInput,
    MainBudgetSettings,
    BudgetScheduler,
    Bidding,
    BaseAlert,
    FrequencyCap,
    ConversionOptions,
    Optimizing
  },

  data () {
    return {
      budget_types: [
        {
          id: 0,
          budget_type: '1',
          title: 'Total',
          name: 'Total'
        },
        {
          id: 1,
          budget_type: '2',
          title: 'Daily',
          name: 'Daily'
        }],
      budget_type: {
        id: 0,
        budget_type: '1',
        title: 'Total',
        name: 'Total'
      },
      interval_days: null,
      showTimePickerEndDate: false,
      showTimePickerStartDate: false,
      max_frequency: null,
      budget_type_time: null,
      currency: null,
      budget: null,
      startDate: null,
      endDate: null,
      optimize_for: null,
      bid_cap: null,
      pay_for: null,
      distributionCheck: false,
      agreement: false,
      bidingStrategy: null,
      start_time: null,
      end_time: null,
      attributionSpec: null,
      modalOpen: false,
      loading: false,
      payList: [],
      conversionsOpen: true,
      endDateCount: null,
      getCampaignsBudgetData: null,
      loadAdSet: true,
      loadingAdSetPreloader: false,
      idCampaign: this.$route.params.campaign_id,
      adSetAmount: null,
      notifications: {
        budget_type_time: 'Total / Daily',
        start_time: 'Start time and Start Date',
        amount: 'Budget',
        pay_for: 'Pay for',
        optimize_for: 'Optimize for',
        bidding: 'Bidding strategy',
        end_time: 'End time and End Date',
        bid: 'Bid',
        attribution_spec: 'Attribution Setting'
      },
      constants: {
        REACH: REACH,
        CONVERSIONS: CONVERSIONS
      }
    }
  },
  created () {
    this.loading = true
    const promises = [
      this.load_campaign({ campaignId: this.idCampaign }),
      this.getCampaigns_budget(this.idCampaign),
      this.load_attribution_spec(this.idCampaign)
    ]

    Promise.allSettled(promises).then(() => {
      const payforObject = this.getCampaigns_budget_data?.meta?.pay_for
      const optimizeFor = this.getCampaigns_budget_data?.meta?.optimize_for

      const attributionSpecLocalStorage = localStorage.getItem(
        `budgetAttributionSpec${this.idCampaign}`
      )

      const budgetOptimizeforLocalStorage = localStorage.getItem(
        `budgetOptimizefor${this.idCampaign}`
      )
      const budgetBidcapLocalStorage = localStorage.getItem(
        `budgetBidcap${this.idCampaign}`
      )
      const budgetStrategyLocalStorage = localStorage.getItem(
        `budgetStrategy${this.idCampaign}`
      )
      const budgetAmountLocalStorage = localStorage.getItem(
        `budgetAmount${this.idCampaign}`
      )
      const endDateLocalStorage = localStorage.getItem(
        `endDate${this.idCampaign}`
      )
      const budgetStartDateLocalStorage = localStorage.getItem(
        `budgetStartDate${this.idCampaign}`
      )
      const budgetAgreementLocalStorage = localStorage.getItem(
        `budgetAgreement${this.idCampaign}`
      )
      const budgetStartTimeLocalStorage = localStorage.getItem(
        `budgetStartTime${this.idCampaign}`
      )
      const budgetEndTimeLocalStorage = localStorage.getItem(
        `budgetEndTime${this.idCampaign}`
      )
      const budgetPayForLocalStorage = localStorage.getItem(
        `budgetPayFor${this.idCampaign}`
      )
      const budgetTypeTimeLocalStorage = localStorage.getItem(
        `budgetTypeTime${this.idCampaign}`
      )
      const maxFrequencyLocalStorage = localStorage.getItem(
        `maxFrequency${this.idCampaign}`
      )
      const intervalDaysLocalStorage = localStorage.getItem(
        `intervalDays${this.idCampaign}`
      )

      // * количество адсетов
      const getAdSetLength = () => {
        if (this.getTargetingAudiencesData.length !== undefined) {
          this.adSetAmount = this.getTargetingAudiencesData?.length
        } else return getAdSetLength()
      }
      getAdSetLength()
      const apiData = this.getCampaigns_budget_data?.data

      // * spec
      if (this.getCreatedCampaign.objective === this.constants.CONVERSIONS) {
        if (apiData.attribution_spec) {
          const index = this.getAttributionSpec.findIndex((spec) => {
            if (spec.code === apiData.attribution_spec) return true
          })
          if (index !== -1) {
            this.attributionSpec = this.getAttributionSpec[index]
          }
        } else if (JSON.parse(attributionSpecLocalStorage)) {
          this.attributionSpec = JSON.parse(attributionSpecLocalStorage)
        } else if (this.getAttributionSpec.length > 0 && this.getAttributionSpec) {
          this.attributionSpec = this.getAttributionSpec[0]
        }
      }

      // * biding strategy
      if (apiData.bidding) {
        this.bidingStrategy = apiData.bidding
      } else if (budgetStrategyLocalStorage) {
        this.bidingStrategy = budgetStrategyLocalStorage
      } else {
        this.bidingStrategy = 'LOWEST_COST_WITHOUT_CAP'
      }

      // * валюта
      this.currency = this.getCampaigns_budget_data?.meta?.currency?.symbol
      // * total/daily
      if (apiData?.budget_type_time) {
        this.budget_type_time = apiData?.budget_type_time
      } else if (budgetTypeTimeLocalStorage) {
        this.budget_type_time = budgetTypeTimeLocalStorage
      } else {
        this.budget_type_time = '2'
      }

      // * bid cap
      if (apiData?.bid) {
        this.bid_cap = apiData.bid
      } else if (budgetBidcapLocalStorage) {
        this.bid_cap = Number(budgetBidcapLocalStorage)
      } else {
        this.bid_cap = null
      }

      // * campaign budget optimization
      if (apiData?.use_budget_optimization === 1) {
        this.agreement = true
      } else if (apiData.use_budget_optimization === 0) {
        this.agreement = false
      } else if (budgetAgreementLocalStorage) {
        this.agreement = JSON.parse(budgetAgreementLocalStorage)
      } else {
        this.agreement = false
      }

      if (apiData?.start_time) {
        this.startDate = this.$moment(apiData?.start_time).format('YYYY-MM-DD')
        this.start_time = this.$moment(apiData?.start_time).format('HH:mm:00')
      } else if (budgetStartDateLocalStorage && budgetStartTimeLocalStorage) {
        this.startDate = budgetStartDateLocalStorage
        this.start_time = budgetStartTimeLocalStorage
      } else {
        this.startDate = this.$moment().format('YYYY-MM-DD')
        this.start_time = this.$moment()
          .add(this.$moment.duration(1, 'hours'))
          .format('HH:mm:00')
      }

      if (apiData?.end_time) {
        this.endDate = this.$moment(apiData.end_time).format('YYYY-MM-DD')
        this.end_time = this.$moment(apiData.end_time).format('HH:mm:00')
      } else if (
        endDateLocalStorage !== 'null' &&
        endDateLocalStorage &&
        budgetEndTimeLocalStorage &&
        budgetEndTimeLocalStorage !== 'null'
      ) {
        this.endDate = endDateLocalStorage
        this.end_time = budgetEndTimeLocalStorage
      }

      if (apiData?.pay_for) {
        this.pay_for = {
          label:
            apiData.pay_for[0].toUpperCase() +
            apiData?.pay_for
              .toLowerCase()
              .slice(1)
              .split('_')
              .join(' '),
          code: apiData.pay_for
        }
      } else if (JSON.parse(budgetPayForLocalStorage)) {
        this.pay_for = JSON.parse(budgetPayForLocalStorage)
      } else {
        const payfor = payforObject[Object.keys(payforObject)[0]][0]
        this.pay_for = {
          label: (payfor[0].toUpperCase() + payfor.toLowerCase().slice(1))
            .split('_')
            .join(' '),
          code: payfor
        }
      }

      if (apiData?.optimize_for) {
        this.optimize_for = {
          label:
            apiData?.optimize_for[0].toUpperCase() +
            apiData?.optimize_for
              .toLowerCase()
              .slice(1)
              .split('_')
              .join(' '),
          code: apiData?.optimize_for
        }
      } else if (budgetOptimizeforLocalStorage) {
        this.optimize_for = JSON.parse(budgetOptimizeforLocalStorage)
      } else {
        this.optimize_for = {
          label:
            optimizeFor[0][0].toUpperCase() +
            optimizeFor[0]
              .toLowerCase()
              .slice(1)
              .split('_')
              .join(' '),
          code: optimizeFor[0]
        }
      }

      // * main budget
      if (apiData?.amount) {
        this.budget = apiData.amount
      } else if (budgetAmountLocalStorage) {
        this.budget = Number(budgetAmountLocalStorage)
      } else {
        this.budget = this.minBudget
      }

      // * max_frequency
      if (apiData?.max_frequency) {
        this.max_frequency = apiData.max_frequency
      } else if (maxFrequencyLocalStorage) {
        this.max_frequency = Number(maxFrequencyLocalStorage)
      } else {
        this.max_frequency = 7
      }

      // * interval_days
      if (apiData?.interval_days) {
        this.interval_days = apiData.interval_days
      } else if (intervalDaysLocalStorage) {
        this.interval_days = Number(intervalDaysLocalStorage)
      } else {
        this.interval_days = 1
      }
      this.loading = false
    }).then(() => {
      this.loadAdSetBudget()
    })
  },
  computed: {
    ...mapGetters('budget', [
      'getCampaigns_budget_data',
      'getBudgetsAdSetSpendCap',
      'getAttributionSpec',
      'getCheckBidding'
    ]),
    ...mapGetters('adsManager', [
      'getTargetingAudiencesData',
      'getCreatedCampaign'
    ]),

    minBudget: function (): number {
      const minBudgetDailyLowFreq: number = this.getCampaigns_budget_data?.meta
        ?.min_budgets?.min_daily_budget_low_freq

      const minBudgetHighFreq: number = this.getCampaigns_budget_data?.meta?.min_budgets
        ?.min_daily_budget_high_freq

      const dailyBudgetImp: number = this.getCampaigns_budget_data?.meta
        ?.min_budgets?.min_daily_budget_imp

      const period: number = this.$moment
        .duration(this.$moment(this.endDate).diff(this.$moment(this.startDate)))
        .asDays()

      const checkPeriodIsNaN = Object.is(period, NaN)
      const checkPeriodIsZero = Object.is(period, 0)

      if (this.budget_type_time === '1') {
        switch (this.optimize_for.code) {
          case 'IMPRESSIONS':
          case 'TWO_SECOND_CONTINUOUS_VIDEO_VIEWS':
          case 'AD_RECALL_LIFT':
          case 'REACH':
          case 'OFFSITE_CONVERSIONS':
            if (!checkPeriodIsNaN && !checkPeriodIsZero) {
              return dailyBudgetImp * period * this.adSetAmount
            } else return dailyBudgetImp * 1 * this.adSetAmount
          case 'LINK_CLICKS':
          case 'PAGE_LIKES':
          case 'POST_ENGAGEMENT':
          case 'LANDING_PAGE_VIEWS':
            if (!checkPeriodIsNaN && !checkPeriodIsZero) {
              return minBudgetHighFreq * period * this.adSetAmount
            } else return minBudgetHighFreq * 1 * this.adSetAmount
        }

        if (!checkPeriodIsNaN && !checkPeriodIsZero) {
          return minBudgetDailyLowFreq * period * this.adSetAmount
        } else return minBudgetDailyLowFreq * 1 * this.adSetAmount
      } else {
        switch (this.optimize_for.code) {
          case 'IMPRESSIONS':
          case 'TWO_SECOND_CONTINUOUS_VIDEO_VIEWS':
          case 'AD_RECALL_LIFT':
          case 'REACH':
          case 'OFFSITE_CONVERSIONS':
            return dailyBudgetImp * this.adSetAmount
          case 'LINK_CLICKS':
          case 'PAGE_LIKES':
          case 'POST_ENGAGEMENT':
          case 'LANDING_PAGE_VIEWS':
            return minBudgetHighFreq * this.adSetAmount
          default:
            return minBudgetDailyLowFreq * this.adSetAmount
        }
      }
    },

    checkPeriodTime (): boolean {
      const startTime: string = this.$moment(
        `${this.startDate} ${this.start_time}`
      ).format('YYYY-MM-DD HH:mm')
      const endTime: string = this.$moment(`${this.endDate} ${this.end_time}`).format(
        'YYYY-MM-DD HH:mm'
      )

      const duration = this.$moment.duration(
        this.$moment(endTime).diff(startTime)
      )
      const hours = duration.asHours()

      if (Number(hours) < 24) return true
      else return false
    }
  },

  updated () {
    this.$watch('optimize_for', function () {
      this.pay_for = null
    })
    this.$watch('endDate', function () {
      this.end_time = '00:00:00'
      if (this.endDate === null) this.end_time = null
    })
    this.$watch('attributionSpec', function () {
      localStorage.setItem(`budgetAttributionSpec${this.idCampaign}`, JSON.stringify(this.attributionSpec))
    })
  },

  watch: {
    budget_type_time (v) {
      this.budget_type = this.budget_types.find(type => type.budget_type === v)
      localStorage.setItem(
        `budgetTypeTime${this.idCampaign}`,
        this.budget_type_time
      )
    },
    max_frequency () {
      localStorage.setItem(`maxFrequency${this.idCampaign}`, this.max_frequency)
    },
    interval_days () {
      localStorage.setItem(`intervalDays${this.idCampaign}`, this.interval_days)
    },
    startDate () {
      localStorage.setItem(`budgetStartDate${this.idCampaign}`, this.startDate)
    },
    agreement () {
      localStorage.setItem(`budgetAgreement${this.idCampaign}`, this.agreement)
    },
    start_time () {
      localStorage.setItem(`budgetStartTime${this.idCampaign}`, this.start_time)
    },
    end_time () {
      localStorage.setItem(`budgetEndTime${this.idCampaign}`, this.end_time)
    },
    pay_for () {
      localStorage.setItem(
        `budgetPayFor${this.idCampaign}`,
        JSON.stringify(this.pay_for)
      )
    },
    optimize_for (optimize) {
      localStorage.setItem(
        `budgetOptimizefor${this.idCampaign}`,
        JSON.stringify(optimize)
      )
      this.getPayForList(optimize)
      this.load_bidding({ id: this.idCampaign, optimize: this.optimize_for.code })
    },

    // bid_cap () {
    //   if (this.bid_cap === '') this.bid_cap = null
    //   else localStorage.setItem(`budgetBidcap${this.idCampaign}`, this.bid_cap)
    //
    //   if (Number(this.bid_cap) > Number(this.budget)) this.bid_cap = this.budget
    //
    //   if (this.bid_cap !== null) {
    //     this.checkInteger('bid_cap')
    //   }
    // },

    bidingStrategy () {
      localStorage.setItem(
        `budgetStrategy${this.idCampaign}`,
        this.bidingStrategy
      )
      if (this.bidingStrategy === 'LOWEST_COST_WITHOUT_CAP') {
        this.bid_cap = null
      }
    },

    budget () {
      if (this.budget !== null) {
        this.checkInteger('budget')
        localStorage.setItem(`budgetAmount${this.idCampaign}`, this.budget)
      }
    },

    endDate () {
      localStorage.setItem(`endDate${this.idCampaign}`, this.endDate)
    }
  },

  methods: {
    ...mapActions('budget', [
      'getCampaigns_budget',
      'create_budget',
      'load_adSet_spendCap',
      'load_attribution_spec',
      'load_bidding'
    ]),
    onlyNumbers,
    ...mapActions('notifications', ['addNotification']),
    ...mapActions('adsManager', ['load_campaign']),
    // * меняем тип Total / Daily
    change_type (type: string): void {
      this.budget_type_time = type
    },
    changeBudgetType (data) {
      this.budget_type = data.activeTab
      this.budget_type_time = String(data.activeTab.budget_type)
    },
    getTime (time, key) {
      if (time !== null) {
        const hours = this.$moment(time, ['HH:mm:ss']).format('hh')
        const minutes = this.$moment(time, ['HH:mm:ss']).format('mm')
        const format = this.$moment(time, ['HH:mm:ss']).format('a')
        if (key === 'hours') return hours
        if (key === 'minutes') return minutes
        if (key === 'format') return format.toUpperCase()
      }
    },
    formattedTime (time) {
      return this.$moment(time, ['HH:mm:ss a']).format('hh:mm:ss A')
    },
    changeTime (acc) {
      if (acc.key === 'endTime' && acc.time.format24 !== null) {
        this.end_time = acc.time.format24 + ':00'
      }
      if (acc.key === 'startTime' && acc.time.format24 !== null) {
        this.start_time = acc.time.format24 + ':00'
      }
    },

    showTimePicker (key) {
      if (this.startDate !== null && key === 'startTime') this.showTimePickerStartDate = true
      if (this.startDate !== null && this.endDate !== null && key === 'endTime') this.showTimePickerEndDate = true
    },

    checkCheckedSpec (option: { code: string, label: string }, attributionSpec: { code: string, label: string }): boolean {
      if (option?.label === attributionSpec?.label) return true
      else return false
    },

    checkDistributionType (type: { label: string, value: string}): void {
      if (type.value === 'manually' || type.value === 'proportionally') {
        this.distributionCheck = true
      } else {
        this.distributionCheck = false
      }
    },

    changeMainBudget (adSetBudget: number) {
      this.budget = adSetBudget
    },

    // * Меняем переданное поле, если оно отрицательное / не имеет валидный вид
    checkInteger (field: string) {
      // * проверяем и редактируем, чтобы пользователь не ввел неправильно число например 00.10.1 будет 0.1
      if (this[field][0] === '0' && this[field][1] === '0') {
        this[field] = `${Math.abs(this[field])}`
      }

      // * если пользователь вводит отрицательное число, то меняем его на это же число с положительным знаком
      if (Number(this[field]) < 0) {
        this[field] = `${Math.abs(this[field] * -1)}`
      }
    },

    // * функция, которая запрещает выбрать дату до сегодняшнего дня,
    disabledStartDate (date: Date): boolean {
      return (
        date <
        new Date(
          this.$moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        )
      )
    },

    // *функция, которая запрещает выбрать дату до выбранной startDate
    beforeDisabledEndDate (date: Date): boolean {
      return (
        date <
        new Date(
          this.$moment(this.startDate)
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        )
      )
    },

    // * формируем массив для опций селекта optimize
    getOptimizeForList (optimizeList: Array<string>): { label: string, code: string }[] {
      if (optimizeList) {
        const result = optimizeList.map(optimize => {
          const string = (
            optimize[0].toUpperCase() + optimize.toLowerCase().slice(1)
          )
            .split('_')
            .join(' ')
          return {
            label: string,
            code: optimize
          }
        })
        return result
      }
    },

    // * формируем массив для опций pay for
    getPayForList (optimize: { code: string, label: string }): void {
      const payForList = this.getCampaigns_budget_data?.meta?.pay_for

      this.payList = payForList[optimize.code].map(payFor => {
        const string = (payFor[0].toUpperCase() + payFor.toLowerCase().slice(1))
          .split('_')
          .join(' ')
        return {
          code: payFor,
          label: string
        }
      })
    },

    // * шлем запрос на создание бюджета. Проверяем заполнены ли поля
    createBudget () {
      interface requestBodyType {
        ['budget_type_time']: string
        ['start_time']: string
        ['amount']: string
        ['pay_for']: string
        ['optimize_for']: string
        ['bidding']: string
        ['use_budget_optimization']: boolean
        ['end_time']?: string
        ['bid']?: number
        ['interval_days']?: number
        ['max_frequency']?: number
        ['attribution_spec']?: string
      }
      const startTime: string | null = this.start_time === null ? null : `${this.startDate} ${this.start_time}`
      const endTime: string | null =
        (this.end_time || this.endDate) === null
          ? null
          : `${this.endDate} ${this.end_time}`

      const requestBody: requestBodyType = {
        budget_type_time: this.budget_type_time,
        start_time: startTime,
        amount: this.budget,
        pay_for: this.pay_for === null ? null : this.pay_for.code,
        optimize_for:
          this.optimize_for === null ? null : this.optimize_for.code,
        bidding: this.bidingStrategy,
        use_budget_optimization: this.agreement,
        bid: Number(this.bid_cap),
        interval_days: Number(this.interval_days),
        max_frequency: Number(this.max_frequency)
      }

      if (endTime !== null) requestBody.end_time = endTime

      if (
        this.bidingStrategy === 'LOWEST_COST_WITH_BID_CAP' ||
        this.bidingStrategy === 'COST_CAP'
      ) {
        requestBody.bid = this.bid_cap
      }

      if (this.bidingStrategy === 'LOWEST_COST_WITHOUT_CAP') {
        delete requestBody.bid
      }

      if (this.getCreatedCampaign.objective === this.constants.CONVERSIONS) {
        requestBody.attribution_spec = this.attributionSpec.code
      }

      //*  проверяем все ли пользователь ввел, если да, то отправляем запрос на создание бюджета
      const checkRequestBody = (): boolean => {
        let result = true
        if (this.budget < this.minBudget) {
          result = false
          this.addNotification({
            body: 'The budget is too small',
            type: 'danger'
          })
        } else {
          for (const key in requestBody) {
            if (requestBody[key] === null || requestBody[key] === undefined) {
              result = false
              this.addNotification({
                body: `Please fill field ${this.notifications[key]}`,
                type: 'danger'
              })
            }
          }
          return result
        }
      }

      if (checkRequestBody() === true) {
        this.create_budget({
          requestBody: requestBody,
          id: this.idCampaign
        })
          .then(() => {
            this.addNotification({
              body: 'Success',
              type: 'success'
            })
            this.$router.push({
              name: 'NewCampaignReview',
              props: { campaign_id: this.idCampaign }
            })
          })['catch'](error => {
            return error.response?.data?.errors.forEach(error => {
              this.addNotification({
                body: `${error.detail}`,
                type: 'danger'
              })
            })
          })

        this.loading = false
      }
    },

    loadAdSetBudget (): void {
      this.loadingAdSetPreloader = true
      if (
        this.loadAdSet === true &&
        this.budget_type_time !== null &&
        this.budget !== null
      ) {
        this.loadingAdSetPreloader = true
        this.loadAdSet = false
        const promises = [
          this.load_adSet_spendCap({
            id: this.idCampaign,
            type: { budget_type: this.budget_type_time }
          })
        ]

        Promise.allSettled(promises).then(() => {
          this.loadingAdSetPreloader = false
        })
      }
    }
  }
})
